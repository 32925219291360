
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@1,600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');

/* CEC3D6, 9FA0B3, 3D588A, 2E3957, 161D2E */
/*font-family: 'Quicksand', sans-serif;*/

body{
  padding: 0px;
  margin: 0px;
  background-image: url('../public/images/background.jpg');
  background-size: cover;
}
.App{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}



/* BUTTONS */
.btns-container{
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.link{
  text-decoration: none;
}

.btn{
  
  border: transparent;
  background: transparent;
  margin: 15px;
  background-color: #e4d100;
  border-radius: 5px;
  color: white;
  width: 120px;
  font-size: 20px;
  border: solid 3px #e4d100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0%;
}
/* BUTTONS END*/

.border1{
  border: solid blue 2px;
  border-radius: 5px;
}